<template>
  <v-container fluid class="pa-6">
    <v-row no-gutters align="center" v-if="!showDataOnly"
      ><v-col cols="12" md="6" align="start"
        ><h2>
          <v-icon color="primary" large left>{{ icon }}</v-icon
          >{{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2>
      </v-col></v-row
    >
    <v-data-table
      :items="items"
      :options="pagination"
      :items-per-page.sync="pagination.rowsPerPage"
      :server-items-length.sync="totalCount"
      :page.sync="pagination.page"
      :loading="loading"
      :headers="names"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions
      }"
    >
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
            ><v-toolbar-title>Info</v-toolbar-title></v-toolbar
          >
          <v-alert
            border="bottom"
            colored-border
            color="primary"
            elevation="2"
            type="info"
          >
            No data available
          </v-alert>
        </v-card>
      </template>
      >
      <template v-slot:loading>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense
            ><v-toolbar-title>Loading data...</v-toolbar-title></v-toolbar
          >
          <v-alert border="bottom" colored-border color="primary" elevation="2">
            <v-progress-circular indeterminate color="primary" />
          </v-alert>
        </v-card>
      </template>

      <template v-slot:item.accessed_at="props">
        {{ humanizeDate(props.item.accessed_at) }}
        ({{ formatDate(props.item.accessed_at, "DD.MM.YYYY H:mm:ss") }})
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
import string from "@/mixins/string";
import _ from "lodash";
export default {
  mixins: [date, permission, string],
  components: {},
  name: "SimpleTable",
  props: {
    names: {
      type: Array,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    keyTitle: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    goTo: {
      type: String,
      required: false
    },
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: "Title"
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help"
    },
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      confirmDeleteDialog: false,
      itemsPerPageOptions: [12, 24, 48, -1]
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    totalCount: {
      get() {
        return this.$store.getters[this.namespace + "/total"];
      }
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems", false);
      }
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      }
    }
  },
  created() {
    this.getData();
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    async getData() {
      await this.$store.dispatch(this.namespace + "/getItems", false);
    }
  }
};
</script>
<style scoped>
.dekra-card {
  border-bottom: 5px solid #017d40;
  transition: transform 0.2s;
}
.dekra-card.on-hover {
  transform: scale(1.04);
}
.dekra-error-card {
  border-bottom: 5px solid #f44336;
}
</style>
